<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      limit: 20,
      page: 1,
      attends: [],
    };
  },
  methods: {
    getEmployeesAttendes(page) {
      this.http
        .post("emps/get-attends", {
          limit: this.limit,
          page: page,
          emp_id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          console.log(res);
          this.attends = res.data;
          console.log(this.attends);
        });
    },
  },
  created() {
    this.getEmployeesAttendes(1);
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
      :title="$t('menu.menuitems.hr.subItems.emps-attendes')"
    />
    <table class="table m-0" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
      <thead>
        <tr
          class="text-center text-light"
          style="background-color: #2a3042 !important"
        >
          <th>id</th>
          <th>Employee</th>
          <th>Check In</th>
          <th>Check Out</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="text-center"
          v-for="(emp_attend, index) in attends"
          :key="emp_attend"
        >
          <td>{{ index + 1 }}</td>
          <td>{{ emp_attend?.emp?.name }}</td>
          <td>{{ emp_attend.check_in }}</td>
          <td>{{ emp_attend.cheak_out }}</td>
        </tr>
      </tbody>
    </table>
  </Layout>
</template>
<style scoped>
</style>


